:root {
  --green-dark: #41b06e;
  --green-light: #8decb4;
  --navy-blue: #141e46;
  --creamy: #fff5e0;
  --green-dark-trans: #41b06d14;
  --green-extra-dark: #198754;
}

.backgroundColor{
  background-color: var(--green-light);
  color: var(--navy-blue);
}

img {
  width: 100%;
  height: fit-content;
}

@media (max-width:700px) {
  .location {
    width: 100%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*home css*/

.home-body {
  background-image: url(./assets/blur-hospital.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.left-home-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
  color: var(--navy-blue);
}

.left-home-body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.75;
  /* Change this value to adjust the transparency */
  z-index: -1;
}

.left-home-body h1 {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--green-dark);
  font-weight: bolder;
}

.left-home-body h2 {
  font-size: 2.2rem;
}

.home-body-slogan {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 350px;
}

.right-home-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-card {
  background-color: white;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  width: 75%;
  border-radius: 0.5rem;
  box-shadow: 9px 9px 38px -27px rgba(0, 0, 0, 0.75);
  background-color: white;
  color: var(--navy-blue);
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-card div {
  width: 100%;
}

.clinic-hour-header {
  color: var(--navy-blue);
  margin-top: 1rem;
}

.clinic-hour-header p {
  color: var(--green-dark);
}

.clinic-section {
  color: var(--navy-blue);
}

.clinic-section h5 {
  color: var(--green-dark);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bolder;
}

.clinic-section h3 {
  color: var(--navy-blue) !important;
  font-weight: bold;
}

.clinic-section h6 {
  color: var(--green-dark) !important;
}

.clinic-section p {
  color: var(--navy-blue) !important;
  font-size: larger;
}

.button-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--green-dark);
  color: white;
  width: 350px;

  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 2rem;
  border: transparent;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.3s ease-in;
}

@media (max-width:1080px) {
  .button-card {
    width: 250px;
  }
}

@media (max-width:450px) {
  .button-card {
    width: 200px;
  }
}

.button-card button:hover {
  background-color: var(--green-light);
  box-shadow: 9px 9px 38px -27px rgba(0, 0, 0, 0.75);
}

.about-us-home {
  background-color: white;
}

.about-us-home-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--navy-blue);
  padding-bottom: 5rem;
}

.about-us-home-right div {
  display: flex;
  flex-direction: column;
  /*top right bottom left*/
  padding: 5rem 5rem 0rem 5rem;
  width: 100%;
}

.about-us-home-pic {
  display: flex;
  padding: 0px 20px;
}

.about-us-home-pic img {
  border-radius: 1rem;
  align-items: center;
  margin: auto;
max-width:700px;
  height: 400px;
}
@media (max-width:770px) {
  .about-us-home-pic img {
    height: 300px;
  }
}
.button-home button {
  background-color: var(--green-dark);
  color: white;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 2rem;
  border: transparent;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in;
}

.button-home button:hover {
  background-color: var(--green-light);
  box-shadow: 9px 9px 38px -27px rgba(0, 0, 0, 0.75);
}

.service-card-home {
  background-color: var(--green-dark-trans) !important;
  padding: 1rem;

  border: var(--green-dark) solid 1px !important;
}

.service-card-home:hover {
  background-color: #41b06d6b !important;
  cursor: pointer;
}

.item {
  display: flex;
  flex-wrap: wrap;
  min-width: 33%;
  min-height: 5rem;
}

.carousel {
  overflow: hidden;
  cursor: grab;
}

.inner-carousel {
  display: flex;
}

.custom-icon-button {
  color: var(--green-dark);
  cursor: pointer;
}

.custom-icon-button:hover {
  color: var(--green-light);
  cursor: pointer;
}

@media (min-width: 300px) {
  .item {
    min-width: 100%;
    margin: 1rem;
  }
}

@media (min-width: 768px) {
  .item {
    min-width: 33.33%;
  }
}

.clinic-section.footer-line {
  border-bottom: 1px solid var(--green-dark);
}

.footer-style {
  background-color: var(--green-dark-trans);
  padding: 3rem;
}

.nav-logo {
  width: 3rem;
  height: 3rem;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  margin: auto;
}

.slick-list {
  margin-left: 10px !important;
}

/*About css*/

.about-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--navy-blue);
  padding: 1rem;
}

.about-card p {
  text-align: center;
  font-size: large;
}

.article {
  background-image: url("./assets/images/about/article.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.formS {
  margin: auto;
  color: var(--navy-blue);
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 9px 9px 38px -27px rgba(0, 0, 0, 0.75);
  padding: 1.5rem;
}

.form-control {
  background-color: #f3f3f3;
}



.slider-controls {

  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;

  border-radius: 50%;
}


/*images media query*/


@media (max-width: 600px) {
  .home-body-slogan img{
    width: 290px !important;
  }
}


.doctors-bg{
  background-image: url(./assets/images/staff/IMG_3346x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.staff-bg {
  background-image: url(./assets/images/staff/IMG_3301.JPG);
  background-repeat: no-repeat;
  background-size: cover;
}