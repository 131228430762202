
@import "./assets/css/nav.css";

*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
   
}
